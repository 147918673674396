<template>
  <label class="my-2 select-none text-left capitalize" :class="isRadio" :for="label">
    <div
      v-if="type == 'radio'"
      class="outside w-6 h-6 border-2 border-blue-500 rounded-full flex items-center justify-center"
    >
      <transition name="checked">
        <div
          v-if="modelValue == value"
          class="inside w-3 h-3 bg-blue-500 rounded-full"
        ></div>
      </transition>
    </div>
    <span>{{ label }}<span v-show="required && type != 'radio'" class="text-red-500">*</span></span>
    <input
      v-show="type != 'radio'"
      :class="['border','py-3', 'px-3', 'rounded-lg', 'transition-all', 'focus:ring-blue-500','focus:outline-none','focus:ring-0','focus:border-blue-500', 'invalid:border-red-500' , ...classes]"
      :type="type"
      :name="name"
      :id="label"
      :value="modelValue"
      :required="required"
      @input="onInput"
    />
    <span v-if="error" class="text-xs text-red-500 italic">
        {{error}}
    </span>
    <span v-if="helper" class="text-xs text-gray-500 italic">
        {{helper}}
    </span>
  </label>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      required: false,
      default: "text",
    },
    helper: {
      type: String,
      required: false,
    },
    error: {
      type: String,
      required: false,
    },
    name: {
      type: String,
      required: false,
    },
    modelValue: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    value: {
      type: String,
      required: false,
    },
    submited: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    isRadio() {
      return this.type != "radio" ? "flex flex-col" : "flex gap-x-3";
    },


    isInvalid () {
      return  this.error ; 
    },

    classes() {
      return [
        this.isInvalid ?"border-red-500" : "border-gray-400",
      ]
    }
  },
  methods: {
    onInput(event) {
      if (this.type == "radio") {
        this.$emit("update:modelValue", this.value);
      } else {
        this.$emit("update:modelValue", event.target.value);
      }
    },
  },
};
</script>

<style scoped>
.checked-enter-active,.checked-leave-active {
  transition: all .2s ease-in-out;
}

.checked-enter-from, .checked-leave-to {
  transform: scale(0);
}
</style>
