<template>
  <div class="container mx-auto sm:p-3 p-2">
    <h2 class="text-2xl font-bold my-5">{{ $t("Refund form") }}</h2>

    <form
      @submit.prevent="submitForm"
      class="border p-5 rounded-lg bg-white shadow-sm"
    >
      <div class="inputs grid grid-cols-4 sm:gap-x-5 gap-x-2 my-4">
        <MyInput
          class="col-span-4"
          :label="$t('email')"
          :submited="submited"
          v-model="email"
          type="email"
          required
        />
        <MyInput
          :label="$t('first name')"
          class="col-span-2"
          v-model="firstName"
          required
        />
        <MyInput
          :label="$t('last name')"
          class="col-span-2"
          v-model="lastName"
          required
        />
        <MyInput
          class="col-span-4"
          :label="$t('address')"
          v-model="address"
          required
        />
        <MyInput class="col-span-3" :label="$t('city')" v-model="city" required />
        <MyInput
          class="col-span-1"
          :label="$t('post code')"
          v-model="postCode"
          required
        />

        <MyInput
          class="col-span-4"
          :label="$t('Order number')"
          v-model="orderNumber"
          required
          :helper="$t('example') + `: [xx${$i18n.locale.toUpperCase()}]1234` "
        />
      </div>

      <div class="reason flex flex-col my-4">
        <h3 class="text-2xl font-bold">{{ $t("Reason for the complaint") }}</h3>
        <MyInput
          :label="$t('No reason')"
          type="radio"
          name="reason"
          value="none"
          v-model="reason"
          required
        />
        <MyInput
          :label="$t('Product quality')"
          type="radio"
          name="reason"
          value="quality"
          v-model="reason"
          required
        />
        <MyInput
          :label="$t('Damaged product')"
          type="radio"
          name="reason"
          value="damaged"
          v-model="reason"
          required
        />
        <MyInput
          :label="$t('Wrong product')"
          type="radio"
          name="reason"
          value="wrong product"
          v-model="reason"
          required
        />
        <MyInput
          :label="$t('Wrong quantity')"
          type="radio"
          name="reason"
          value="quantity"
          v-model="reason"
          required
        />
      </div>

      <div class="complaint flex flex-wrap gap-x-5 my-4" v-if="this.reason">
        <h3 class="text-2xl font-bold w-full">
          {{ $t("Select type of complaint") }}
        </h3>
        <MyInput
          :label="$t('Refund')"
          type="radio"
          name="complaint"
          value="refund"
          v-model="complaint"
          required
        />
        <MyInput
          :label="$t('Product replacement')"
          type="radio"
          name="complaint"
          value="replace"
          v-model="complaint"
          required
        />
      </div>
      <div class="replacement" v-if="this.complaint == 'replace'">
        <MyInput
        class="my-4"
        
        :label="$t('Replacement product')"
        v-model="replacementProduct"
        required
      />
      </div>
    

      <div v-else-if="this.complaint == 'refund'" class="payment flex flex-col my-4">
        <h3 class="text-2xl font-bold">{{ $t("Type of payment") }}</h3>
        <MyInput
          :label="$t('Cash')"
          type="radio"
          name="payment"
          value="cash"
          v-model="payment"
          required
        />
        <MyInput
          :label="$t('Credit card')"
          type="radio"
          name="payment"
          value="card"
          v-model="payment"
          :helper="'(' + $t('Paid on our website, and not with the delivery service / courier') + ')'"
          required
        />
        <MyInput
          label="Paypal"
          type="radio"
          name="payment"
          value="paypal"
          v-model="payment"
          required
        />
        <MyInput
          class="my-4"
          v-if="this.payment == 'cash'"
          label="IBAN"
          v-model="iban"
          :helper="  examples.iban[this.$i18n.locale]"
          :error="ibanErrorMessage"
          required
        />
      </div>
      <MyInput
        class="my-4"
        :label="$t('Comment')"
        v-model="comment"
        type="textarea"
      />
      <button
        type="submit"
        class="
          bg-green-600
          hover:bg-green-700
          transition-all
          w-full
          py-5
          rounded-lg
          text-xl text-white
          flex
          flex-row
          justify-center
          text-center
          items-center
          disabled:opacity-50
        "
        :disabled="isLoading"
      >
      {{ isRequiredFilled }}
      <svg class="animate-spin -ml-1 mr-3 h-8 w-8 text-white" v-if="isLoading" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
        {{ $t("Submit the form") }}
      </button>
      <p class="error text-red-600" v-if="errorMessage">
        {{ $t(errorMessage) }}
      </p>
    </form>
  </div>
</template>

<script>
import MyInput from "@/components/MyInput.vue";
import axios from "axios";
import isValidIBan from "@/utils/iban";

export default  {
  components: {
    MyInput: MyInput,
  },
  created() {
    if (process.env.NODE_ENV === "development") {
      console.log("🚧Running in development mode");
    } else {
      console.log("✔️Running in production mode");
    }
  },
  data() {
    return {
      ...this.initialState(),
    }
  },
  computed: {
    ibanErrorMessage() {
      if (this.iban) {
        return isValidIBan(this.iban) ? "" : "Invalid IBAN";
      }
      return null;
    },

  },


  methods: {
    initialState() {
      return {
      isLoading: false,
      submited: false,
      errorMessage: "",
      email: "",
      firstName: "",
      lastName: "",
      address: "",
      postCode: "",
      city: "",
      orderNumber: "",
      reason: "",
      complaint: "",
      payment: "",
      iban: "",
      comment: "",
      replacementProduct: "",
      examples: {
        "iban": {
          'hr': 'HR1210010051863000160',
          'sk': 'SK3112000000198742637541',
          'bg': 'BG83BNBG96611020345678',
          'cs': 'CZ6508000000192000145399',
          'pl': 'PL61109010140000071219812874',
          'ro': 'RO49AAAA1B31007593840000',
          'hu': 'HU42117730161111101800000000',
          'si': 'SI56263300012039086',
          'es': 'ES9121000418450200051332',
          'it': 'IT60X0542811101000000123456'
        }
      },

      }
    },

    // Get source query
    async submitForm() {
      this.submited = true; // Track if form is submited to show errors
      this.errorMessage = ""; // Reset error message

      const payload = {
        email: this.email,
        first_name: this.firstName,
        last_name: this.lastName,
        address: this.address,
        post_number: this.postCode,
        location: this.city,
        support_type: this.complaint,
        replacement_product: this.replacementProduct,
        reason: this.reason,
        order_number: this.orderNumber,
        payment_type: this.payment,
        sender_email: 'returns@bt-company.com',
        sender_name: 'BT Company',
        comment: this.comment,
        IBAN: this.iban,
        lang: this.$i18n.locale,
        store: this.$route.query.source,
        page_type: this.$route.query.incomplete ? 'nepopolna_reklamacija' : 'reklamacija'
      };

      console.log(payload);

      try {
        const port = process.env.PORT || 3000;
        const url =
          process.env.NODE_ENV == "production"
            ? `https://returns.bt-company.com/api`
            : `http://localhost:${port}/`;


        this.isLoading = true;
        const {data} = await axios.post(url, payload);
      
        // Reset form data
        Object.assign(this.$data, this.initialState());


    


        const newUrl = `/${this.$i18n.locale}/success`;
        this.$router.push({path: newUrl, query: {id: data.data.id, email: data.data.email}});
      } catch (err) {
        console.error(err);
        // Show error message on the form
        this.isLoading = false;
        this.errorMessage = err.response.data.message;
  

        // this.errorMessage = "Something went wrong, please try again later";
      }
    },
  },
};
</script>

<style lang="css" scoped>
  h3 {
    text-align: left;
  }
</style>